<template>
  <svg viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.2544 4.87699V3.42617H13.8036V1.97536C13.8036 1.1774 13.1507 0.524536 12.3528 0.524536H2.19703C1.39908 0.524536 0.746216 1.1774 0.746216 1.97536V12.1311C0.746216 12.929 1.39908 13.5819 2.19703 13.5819H12.3528C13.1507 13.5819 13.8036 12.929 13.8036 12.1311V10.6803H15.2544V9.22945H13.8036V7.77863H15.2544V6.32781H13.8036V4.87699H15.2544ZM12.3528 12.1311H2.19703V1.97536H12.3528V12.1311ZM3.64785 7.77863H7.2749V10.6803H3.64785V7.77863ZM8.00031 3.42617H10.9019V5.6024H8.00031V3.42617ZM3.64785 3.42617H7.2749V7.05322H3.64785V3.42617ZM8.00031 6.32781H10.9019V10.6803H8.00031V6.32781Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'IconMoodboard'
}
</script>
